// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-article-js": () => import("../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-landing-page-js": () => import("../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-pl-blog-js": () => import("../src/pages/pl/blog.js" /* webpackChunkName: "component---src-pages-pl-blog-js" */),
  "component---src-pages-pl-szkola-js": () => import("../src/pages/pl/szkola.js" /* webpackChunkName: "component---src-pages-pl-szkola-js" */),
  "component---src-pages-szkola-js": () => import("../src/pages/szkola.js" /* webpackChunkName: "component---src-pages-szkola-js" */)
}

