module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Baloo","Roboto Condensed:300,400,700","Roboto+Condensed:italic","Roboto:300,400,700","Poppins:300,400,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Employee Excellence Platform | Grow Uperion","short_name":"Grow Uperion","description":"Grow Uperion lets you meet business goals through psychological motivation and improving engagement of your employees. Learn more about Grow Uperion.","lang":"en","display":"standalone","icon":"src/images/favicon-96x96.png","start_url":"/","background_color":"#2a9d8f","theme_color":"#323b4c","cache_busting_mode":"none","legacy":true,"localize":[{"start_url":"/pl","lang":"pl","name":"Sprytne motywowanie pracowników | Grow Uperion","short_name":"Grow Uperion","description":"Grow Uperion to sprytna platforma, pozwalająca realizować twarde cele biznesowe poprzez wielowymiarowe psychologiczne motywowanie i wzrost zaangażowania."},{"start_url":"/szkola/","lang":"pl","name":"Grow Uperion - sprawdzona platforma grywalizacyjna dla szkół","short_name":"Grow Uperion Szkoła","description":"Wzmacnia zaangażowanie uczniów, pomaga rodzicom pracującym w domu, ułatwia pracę nauczycielom."}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
