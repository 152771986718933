export const CONTENT_ITEMS_TYPE = {
  LINKED_IMAGE: "KontentItemLinkedImage",
  VIDEO_CONTENT: "KontentItemVideoContent",
  DOWNLOAD_SECTION: "KontentItemEbookDownloadSection"
}

export const FORMS_TYPES = {
  CONTACT: "contact_form",
  DATE: "date_form",
}

export const SLIDES_TYPES = {
  SERVICE_LINE: "service line",
  PERSON: "person",
  TEAM: "team",
  OPEN_POSITION: "open position",
  ICON: "icon",
  NUMBER: "number",
}

export const KENTICO_CONTENT_TYPES = {
  ACCORDION_SECTION: "ui_accordion_section",
  CONTACT_SECTION: "ui_contact_section",
  CUSTOMERS_REFERENCES_SECTION: "ui_customer_references_section",
  CUSTOMERS_SECTION: "ui_customers_section",
  DOWNLOAD_SECTION: "download_section",
  THREE_FOUR_COLUMN_SECTION: "ui_three_four_columns_section",
  HOW_IT_WORKS_SECTION: "ui_how_it_works_section",
  LANDING_PAGE_CHAPTER_HEADER: "landing_page_chapter_header",
  LANDING_PAGE_HEADER: "landing_page_header",
  LANDING_PAGE_IMAGES_ROW: "landing_page_images_row",
  LANDING_PAGE_NAVIGATOR: "landing_page_navigator",
  LANDING_PAGE_SECTION: "landing_page_section",
  LANDING_PAGE_TESTIMONIAL_SECTION: "landing_page_testimonial_section",
  LANDING_PAGE_TWO_COLUMNS_SECTION: "landing_page_two_columns_section",
  LANDIGN_PAGE_VIDEO_SECTION: "landing_page_video_section",
  LANDING_PAGE_PARAGRAPH_SECTION: "landing_page_paragraph_section",
  METHODOLOGY_SECTION: "ui_methodology_section",
  NAVIGATOR: "navigator",
  NUMBERS_SECTION: "ui_numbers_section",
  ONE_COLUMN_SECTION: "ui_one_column_section",
  ONE_COLUMN_WITH_SLIDER: "ui_one_column_with_slider",
  OUR_TEAM_SECTION: "ui_our_team_section",
  PAGE_HEADER: "page_header",
  RICH_TEXT: "rich_text_component",
  TWO_COLUMNS_HEADER: "two_columns_page_header",
  TWO_COLUMNS_LIST: "ui_two_columns_list",
  TWO_COLUMN_SECTION: "ui_two_column_section",
  TWO_COLUMNS_WITH_SLIDER: "ui_two_columns_with_slider",
  TWO_COLUMNS_WITH_TITLE: "ui_two_columns_with_title",
}

export const DEFAULT_BACKGROUND_COLOR = "#ffffff"

export const MONTHS = {
  PL: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],
  EN: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
}

export const LANGUAGES = {
  EN: "en",
  PL: "pl",
  // DE: "de",
  // SV: "sv",
}

export const PAGE_LANGUAGES = [
  {
    path: "/pl/",
    lang: LANGUAGES.PL,
  },
  {
    path: "/blog",
    lang: LANGUAGES.EN,
  },
  {
    path: "/pl/blog",
    lang: LANGUAGES.PL,
  },
  {
    path: "/investors",
    lang: LANGUAGES.PL,
  },
  {
    path: "/szkola",
    lang: LANGUAGES.PL,
  },
  {
    path: "/school",
    lang: LANGUAGES.EN,
  },
]

export const POSTS_PER_PAGE = 18
