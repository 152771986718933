import React, { useState, createContext } from "react"

import { LANGUAGES } from "../utils/consts"

export const LangContext = createContext(null)

const languages = Object.values(LANGUAGES).map(lang =>
  lang.toLowerCase()
)

const isLang = lang => languages.includes(lang)

const getLangFromPathname = () => {
  const pathname = decodeURI(typeof window !== "undefined" ? window.location.pathname : '')
  const lang = pathname.split("/")[1]

  return isLang(lang) ? lang : LANGUAGES.EN
}

const Provider = ({ children } = null) => {
  const [pageLang, setPageLang] = useState(getLangFromPathname())

  const toggleLang = lang => {
    localStorage.setItem('language', lang)
    setPageLang(lang)
  }

  return (
    <LangContext.Provider
      value={{
        languages,
        pageLang,
        toggleLang,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
